import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../src/index";
import { observer } from "mobx-react-lite";
import { DataTable } from "../Common/DataTable/DataTable";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ServerModal from "./ServerModal";
import Modal from "../Common/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { DataTable3 } from "../Server/DataTable3";
import { token } from "../../libs/localStorage";
import Form, { FormBody, FormActions, TextInput, SelectInput, DateInput } from '../Common/Form';
import ClipLoader from "react-spinners/ClipLoader";
import { DataTable4 } from "./DataTable4";
import { DataTable7 } from "./DataTable7";


const DeviceList = observer(() => {
    const rootStore = useContext(RootContext);
    const deviceStore = rootStore.deviceStore;
    const serverStore = rootStore.serverStore
    const simDetailStore = rootStore.simDetailStore
    const dataUsageStore = rootStore.dataUsageStore
    const decodedToken = token.getDecoded();

    const [showAddDevice, setShowAddDevice] = useState(false);
    const [showAddServer, setShowAddServer] = useState(false);
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")


    const [deviceInfo, setDeviceInfo] = useState({
        serialNumber: "",
        modelNo: "",
        deviceId: "",
        planType: "",
        serviceProvider: "",
        protocol: "",
        folder:""
    });

    const [simInfo, setSimInfo] = useState({
        puk1: "",
        puk2: "",
        pin1: "",
        pin2: "",
        purchasedate: new Date().toISOString().substr(0, 10),
        loginpassword: "",
        stationname: ""
    });

    const [addedServerOptions, setAddedServerOptions] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        deviceStore.fetchdevice();
        deviceStore.fetchplan();
        deviceStore.fetchprotocol();
        deviceStore.fetchfolders();
        deviceStore.fetchserviceProvider();
        serverStore.fetchServers();
        simDetailStore.fetchToRechargeSim()
    }, []);

    useEffect(() => {
        if (deviceInfo.serviceProvider == "19") {
            setSimInfo(prev => ({ ...prev, loginpassword: 'Windowsdeviceroot123*#' }));
        } else {
            setSimInfo(prev => ({ ...prev, loginpassword: 'rts123' }));
        }
    }, [deviceInfo.serviceProvider]);


    const setNewForm = (name, e) => {
        const { value } = e.target
        setDeviceInfo((prev) => ({ ...prev, [name]: value }));
    };

    const setNewForm1 = (e) => {
        const { name, value } = e.target;
        setDeviceInfo((prev) => ({ ...prev, [name]: value }));
    };

    const setNewForm2 = (e) => {
        const { name, value } = e.target;
        setSimInfo((prev) => ({ ...prev, [name]: value }));
    };

    const setNewAddedServerInfo = (e) => {
        const { name, value } = e.target;
        setAddedServerOptions((prev) => [...prev, { [name]: value }]);

    };

    const getPlanValue = (planId) => {
        const value = deviceStore.plan.find((plan) => plan.id == planId)
        return value ? value.volume : ""
    }

    const getServerId = (serverName) => {
        const value = serverStore.servers.find((server) => server.domain_name == serverName)
        return value ? value.id : ""
    }

    const getProtocolValue = (protocolId) => {
        const value = deviceStore.protocol.find((protocol) => protocol.id == protocolId)
        return value ? value.caption : ""
    }

    const getServiceProvider = (providerId) => {
        const value = deviceStore.serviceProvider.find((protocol) => protocol.id == providerId)
        return value ? value.caption : ""
    }

    const showServerForm = (e) => {
        e.preventDefault()
        setShowAddServer(true);
    }

    const checkPassword = async (e) => {
        e.preventDefault()
        setLoading(true)
        const serviceProvider = getServiceProvider(deviceInfo.serviceProvider)

        const requiredData = {
            phoneNumber: deviceInfo.deviceId,
            provider: serviceProvider,
            loginpassword: simInfo.loginpassword
        }
        const returnedData = await simDetailStore.automaticPasswordChecker(requiredData)
        if (returnedData && returnedData.message == "Success") {
            setMessage("Password Matched")
            setLoading(false)
        } else {
            setMessage("Password does not match")
            setLoading(false)
        }
    }


    const removeServerOption = (index) => {
        const updatedServerOptions = addedServerOptions.slice();
        updatedServerOptions.splice(index, 1);
        setAddedServerOptions(updatedServerOptions);
    };

    const deleteDevice = async (id) => {
        const confirm = window.confirm("Delete?");

        if (selectedRows.length != 0 && confirm) {
            for (let i = 0; i < selectedRows.length; i++) {
                await deviceStore.deleteDevice(selectedRows[i])
            }
        } else {
            await deviceStore.deleteDevice(id)
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault()

        if (!deviceInfo.serialNumber || !deviceInfo.planType || !deviceInfo.serviceProvider || !deviceInfo.protocol) {
            toast.error("Missing fields");
            return;
        }

        if (!deviceInfo.deviceId) {
            toast.error("DeviceId/ Sim Number is required");
            return;
        }

        const phoneNumber = deviceInfo.deviceId
        const provider = await getServiceProvider(deviceInfo.serviceProvider)

        const addDevice = await deviceStore.addDevice(deviceInfo)
        console.log(addDevice,"addDeviceeeeeeeeee");
        const addedDeviceId = addDevice?.id
        


        const deviceId = deviceInfo.deviceId
        // const devicesWithCounter = deviceStore.items.filter(device => device.counter !== null && device.counter !== undefined)[0].counter;
        const devicesWithCounters = deviceStore.items.map((device) => device.counter);
        const devicesWithCounter = Math.max(...devicesWithCounters);

        let addedDevice2server
        const addDevicePromises = addedServerOptions.map(async (serverOption) => {
            const serverId = getServerId(serverOption.serverSelection);
            return await deviceStore.addDevice2Server({ serverId, deviceId, counter: devicesWithCounter });
        });

        const deivce_detail = await Promise.all(addDevicePromises);

        if (addedDeviceId) {
            const simInfoWithPhoneNumber = {
                ...simInfo,
                phonenumber: phoneNumber,
                device_id: addedDeviceId,
                serviceProvider: provider
            };
    
            if (!simInfo.loginpassword || simInfo.pin1 || simInfo.pin2 || simInfo.puk1 || simInfo.puk2 || simInfo.pin1 || simInfo.purchasedate) {
                const addSimDetails = await simDetailStore.addSimDetails(simInfoWithPhoneNumber)
                const addEmail = await simDetailStore.createEmail({ email: decodedToken.email, simId: addedDeviceId, device_id: phoneNumber });
            }
              await deviceStore.fetchdevice();
        }
        

      

        setDeviceInfo({ serialNumber: "", deviceId: "", modelNo: "", planType: "", serviceProvider: "", protocol: "" });
        setSimInfo({
            puk1: "",
            puk2: "",
            pin1: "",
            pin2: "",
            purchasedate: "",
            loginpassword: "",
            stationname: ""
        })
        setAddedServerOptions([])

        setShowAddDevice(false)
    }

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };

    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };

    // <DataTable7
    //          title={"All Stations"}
    //             data={deviceStore.device
    //                 .map((item, index) => {
    //                     return {
    //                         ...item,
    //                         SN: index + 1,
    //                         plan_id: getPlanValue(item.plan_id),
    //                         protocol_id: getProtocolValue(item.protocol_id),
    //                         service_provider_id: getServiceProvider(item.service_provider_id)
    //                     }
    //                 })

    //             }
    //             handleAdd={() => setShowAddDevice(true)}
    //             headers={[
    //                 { key: "SN", label: "SN" },
    //                 // { key: "model_no", label: "ModelNumber" },
    //                 { key: "device_id", label: "DeviceID" },
    //                 { key: "serial_no", label: "SerialNumber" },
    //                 // { key: "plan_id", label: "Plan(MB)" },
    //                 // { key: "protocol_id", label: "Protocol" },
    //                 { key: "service_provider_id", label: "ServiceProvider" }
    //             ]}
    //             // handleEdit={(id) => {
    //             //     navigate(`/devices/list/device/${id}`);
    //             // }}
    //             handleDelete={(e) => deleteDevice(e)}
    //             handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
    //         // onRowClick={handleRowClick}
    //         />

    return (
        <div>
            <DataTable4
            title={"To Recharge Stations"}
                data={simDetailStore.toRechargeData
                    .map((item, index) => {
                        return {
                            ...item,
                            SN: index + 1,
                        }
                    })

                }
                handleAdd={() => setShowAddDevice(true)}
                headers={[
                    { key: "SN", label: "SN" },
                    { key: "phonenumber", label: "Number" },
                    { key: "remaining_balance", label: "Balance" },
                    { key: "remaining_volume", label: "Volume" },
                    { key: "station_name", label: "Station" }
                ]}
            // onRowClick={handleRowClick}
            />
            <br/>
            
            {showAddDevice && (
                <Modal
                    title={"Add Device"}
                    closeModal={() => {
                        setShowAddDevice(false);
                        setDeviceInfo({ serialNumber: "", deviceId: "", modelNo: "", planType: "", serviceProvider: "", protocol: "" });
                        setSimInfo({ puk1: "", puk2: "", pin1: "", pin2: "", purchasedate: "" });
                        setAddedServerOptions([])
                        setMessage("")
                    }}
                >

                    <div className="main-content">

                        <Form>
                            <FormBody>
                                <TextInput label={'Serial Number'} name="serialNumber" value={deviceInfo.serialNumber} onChange={setNewForm1} />
                                <TextInput label={'DeviceID / Sim Number'} name="deviceId" value={deviceInfo.deviceId} onChange={setNewForm1} />
                                <SelectInput
                                    label={'Plan Type'}
                                    name={'planType'}
                                    fields={{ label: 'caption', value: 'id' }}
                                    options={deviceStore.plan}
                                    value={deviceInfo.planType}
                                    onChange={(value) => setNewForm("planType", value)}
                                />
                                <SelectInput
                                    label={'Folder'}
                                    name={'folder'}
                                    fields={{ label: 'name', value: 'id' }}
                                    options={deviceStore.folders}
                                    value={deviceInfo.folder}
                                    onChange={(value) => setNewForm("folder", value)}
                                />
                                <SelectInput
                                    label={'Service Provider'}
                                    name="serviceProvider"
                                    fields={{ label: 'caption', value: "id" }}
                                    options={deviceStore.serviceProvider}
                                    value={deviceInfo.serviceProvider}
                                    onChange={(value) => setNewForm("serviceProvider", value)}
                                />
                                <SelectInput
                                    label={'Protocol'}
                                    name="protocol"
                                    fields={{ label: 'caption', value: 'id' }}
                                    options={deviceStore.protocol}
                                    value={deviceInfo.protocol}
                                    onChange={(value) => setNewForm("protocol", value)}
                                />
                            </FormBody>
                        </Form>
                    </div>

                    <div className="secondary-content">
                        <Form>
                            <FormBody>
                                {(deviceInfo.serviceProvider == "19" || deviceInfo.serviceProvider == "18") && (
                                    <>
                                        <TextInput label={'Station Name'} name="stationname" value={simInfo.stationname} onChange={setNewForm2} />
                                        <TextInput label={'Puk1'} name="puk1" value={simInfo.puk1} onChange={setNewForm2} />
                                        <TextInput label={'Puk2'} name="puk2" value={simInfo.puk2} onChange={setNewForm2} />
                                        <TextInput label={'Pin1'} name="pin1" value={simInfo.pin1} onChange={setNewForm2} />
                                        <TextInput label={'Pin2'} name="pin2" value={simInfo.pin2} onChange={setNewForm2} />
                                        <DateInput label={'Purchasedate'} name="purchasedate" value={simInfo.purchasedate || new Date().toISOString().substr(0, 10)} onChange={setNewForm2} />
                                        <TextInput
                                            label={'Login Password'}
                                            name="loginpassword"
                                            value={
                                                simInfo.loginpassword
                                            } onChange={setNewForm2} />
                                       <div style={{ display: "flex", alignItems: 'center' }}>
                                            <button style={{ background: "white", color: 'red', margin: '0' }} onClick={(e) => checkPassword(e)}>Check Password Compatibility</button>
                                            {loading ? (
                                                <ClipLoader
                                                    color="#3C8DBC"
                                                    size={10}
                                                    cssOverride={{ display: "block", margin: 0 }}
                                                />
                                            ) : (
                                                <span style={{ fontWeight: 'bold', fontSize: "15px", color: "black", marginBottom: "3px" }}>{message}</span>
                                            )}

                                        </div> 

                                    </>
                                )}

                            </FormBody>
                        </Form>
                    </div>


                    <div>
                        <div className="addDevice">
                            <div className="addServerOptions" >
                                {addedServerOptions.map((serverOption, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>{serverOption.serverSelection}</div>
                                        <button
                                            onClick={() => removeServerOption(index)}
                                            style={{ position: 'relative', top: '0', right: '0', fontSize: '6px', padding: '2px', cursor: 'pointer' }}>X</button>
                                    </div>
                                ))}
                            </div>
                            <button onClick={(e) => showServerForm(e)}>Add Server</button>
                        </div>

                        <div className="saveAndcancel">
                            <button onClick={(e) => onSubmit(e)}>Submit</button>
                            <button onClick={(e) => setShowAddDevice(false)}>Cancel</button>
                        </div>

                    </div>

                </Modal>
            )}

            {showAddServer && (
                <ServerModal
                    title={"Add Server"}
                    closeModal={() => {
                        setShowAddServer(false);
                    }}
                >
                    <form
                        className="Form"
                        autoComplete="off"
                    >
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">Server</InputLabel>
                            <Select
                                defaultValue=""
                                name="serverSelection"
                                label="serverSelection"
                                value={addedServerOptions.serverSelection}
                                onChange={setNewAddedServerInfo}
                            >
                                {serverStore.servers.map((server, index) => (
                                    <MenuItem key={server.id} value={server.domain_name}>{server.domain_name.replace('/import', '')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* <button onClick={(e) => addServer(e)}>Add</button> */}
                    </form>

                </ServerModal>
            )}
            <br />
            <Outlet />
        </div>
    );
});


export default DeviceList