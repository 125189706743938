import React from "react";
import { Navigate, createHashRouter, redirect } from "react-router-dom";
// import { checkRedirectToLogin,isLoggedIn } from "./helpers/isLoggedIn";
// import { Login } from "./components/Login";
import App from "./App";
import ServerList from "./components/Server/ServerList";
import "./App.css";
import Home from "./components/NavBar";
import DataUsage from "./components/DataUsage/dataUsage"
import RawData from "./components/RawData/rawData";
import Details from "./components/Details/details"
import { EditServer } from "./components/Server/EditServer";
import { EditDevice2Server } from "./components/Server/EditDevice2Server";
import DeviceList from "./components/Devices/DeviceList";
import ServiceProvider from "./components/Configuration/Service Provider/serviceProvider";
import Plan from "./components/Configuration/Plan/Plan";
import Protocol from "./components/Configuration/Protocol/Protocol";
import EditServiceProvider from "./components/Configuration/Service Provider/editServiceProvider";
import EditPlan from "./components/Configuration/Plan/EditPlan";
import EditProtocol from "./components/Configuration/Protocol/EditProtocol";
import { Login } from "./components/Login";
import { checkRedirectToLogin, isLoggedIn } from "./helpers/isLoggedIn";
import TriggerDevice from "./components/Configuration/Trigger Device/TriggerDevice";
import EditDevice from "./components/Devices/EditDevices";
import SingleDeviceDetail from "./components/SingleDeviceDetail/SingleDeviceDetail";
import SimDetails from "./components/SimBalance/SimBalance";
import SingleSimDetail from "./components/SimBalance/SingleSimDetail";
import Role from "./components/Roles/Role";
import EditUser from "./components/Roles/EditRole";
import DataUsageSim from "./components/DataUsage/dataUsageSim";
import RechargeList from "./components/Recharge.js/RechargeList";
import MetaData from "./components/MetaData/MetaData"
import TagsList from "./components/Tags/TagsList";
import ConfigMetaData from "./components/Configuration/MetaData/ConfigMetaData";
import MetaDataTemplate from "./components/Configuration/MetaData/MetaDataTemplate";
import MetaDataTemplateEdit from "./components/Configuration/MetaData/MetaDataTemplateEdit";
import CheckBalance from "./components/Configuration/Check Balance/CheckBalance";
import CommMetaData from "./components/MetaData/CommMetaData";
import Account from "./components/Account/Account";
import Folder from "./components/Configuration/Folder/Folder";
import DailyUsageSim from "./components/DataUsage/dailyUsageSimGraph";
import ScraperLogs from "./components/Configuration/ScraperLogs/ScraperLogs";
import RechargeForm from "./components/Recharge.js/RechargeForm";
import ErrorLogs from "./components/SingleDeviceDetail/ErrorLogs";
import TimeConverter from "./components/Configuration/TimeConverter/TimeConverter";

export const router = createHashRouter([

	{
		element: <App />,
		loader: checkRedirectToLogin,
		children: [
			{
				path: "/",
				element: <Navigate replace to="/monitoring" />
			},
			{
				path: "/monitoring",
				element: <Navigate replace to="/monitoring/dashboard" />,
			},
			{
				path: "/monitoring/dashboard",
				element: <DeviceList />,
				children: [
					{
						path: "/monitoring/dashboard/device/:id",
						element: <EditDevice />
					}

				]
			},
			{
				path: "/monitoring/checkbalance",
				element: <CheckBalance />
			},
			// {
			// 	path: "/monitoring/scraperlogs",
			// 	element: <ScraperLogs />
			// },
			{
				path: "/monitoring/rechargeform",
				element: <RechargeForm />
			},
			{
				path: "/configuration/server",
				element: <ServerList />,
				children: [
					{
						path: "/configuration/server/:id",
						element: <EditServer />
					}

				]
			},
			{
				path: "/singledevicedetail/sim/metadata/:id",
				element: <MetaData />
			},
			{
				path: "/singledevicedetail/sim/logs/:id",
				element: <ErrorLogs />
			},
			{
				path: "/singledevicedetail/sim/datausage/graph/:id",
				element: <DataUsageSim />
			},
			{
				path: "/singledevicedetail/sim/datausage/dailyusage/:id",
				element: <DailyUsageSim />
			},
			{
				path: "/singledevicedetail/graph/:id",
				element: <DataUsage />
			},
			{
				path: "/singledevicedetail/orbcomm/metadata/:id",
				element: <CommMetaData />
			},
			{
				path: "/singledevicedetail/sim/datausage/detail/:id",
				element: <SingleSimDetail />
			},

			{
				path: "/configuration",
				element: <Navigate replace to="/configuration/serviceprovider" />
			},
			{
				path: "/configuration/serviceprovider",
				element: <ServiceProvider />,
				children: [
					{
						path: "/configuration/serviceprovider/:id",
						element: <EditServiceProvider />
					}

				]
			},
			
			{
				path: "/configuration/plan",
				element: <Plan />,
				children: [
					{
						path: "/configuration/plan/:id",
						element: <EditPlan />
					}

				]
			},
			{
				path: "/configuration/folders",
				element: <Folder />,
				// children: [
				// 	{
				// 		path: "/configuration/plan/:id",
				// 		element: <EditPlan />
				// 	}

				// ]
			},
			{
				path: "/configuration/account",
				element: <Account />
			},
			{
				path: "/configuration/metadata/meta-data",
				element: <ConfigMetaData />,
			},
			{
				path: "/configuration/metadata/metadatatemplate",
				element: <MetaDataTemplate />,
				children: [
					{
						path: "/configuration/metadata/metadatatemplate/:id",
						element: <MetaDataTemplateEdit />
					}

				]
			},
			{
				path: "/configuration/recharge",
				element: <Navigate replace to="/configuration/recharge/list" />,
			},
			{
				path: "/configuration/recharge/list",
				element: <RechargeList />,
			},
			{
				path: "/configuration/tags",
				element: <Navigate replace to="/configuration/tags/list" />,
			},
			{
				path: "/configuration/tags/list",
				element: <TagsList />,
			},
			
			{
				path: "/configuration/simdetails",
				element: <SimDetails />
			},
			{
				path: "/configuration/timeconverter",
				element: <TimeConverter />
			},
			{
				path: "/configuration/roles",
				element: <Role />,
				children: [
					{
						path: "/configuration/roles/:id",
						element: <EditUser />
					}

				]
			},
			{
				path: "/configuration/protocols",
				element: <Protocol />,
				children: [
					{
						path: "/configuration/protocols/:id",
						element: <EditProtocol />
					}

				]
			},
			{
				path: "/configuration/triggerdevice",
				element: <TriggerDevice />,
			},
			{
				path: "/monitoring/rawData",
				element: <RawData />
			},
			{
				path: "/details/:deviceId/:id",
				element: <Details />
			},
			// {
			// 	path: "/servers",
			// 	element: <ServerList />,
			// 	children: [
			// 		// {
			// 		// 	path: "/servers/user-server/:id",
			// 		// 	element: <EditUserServer />
			// 		// },
			// 		{
			// 			path: "/servers/server/:id",
			// 			element: <EditServer />
			// 		},
			// 		{
			// 			path: "/servers/device2server/:id",
			// 			element: <EditDevice2Server />
			// 		}

			// 	]
			// }
		]
	},
	{
		path: "/login",
		element: <Login />,
		loader: () => {
			if (isLoggedIn()) return redirect("/");
			return null;
		}
	}
	// {
	// 	path: "/signup",
	// 	element: <Signup />,
	// 	// loader: () => {
	// 	// 	if (isLoggedIn()) return redirect("/");
	// 	// 	return null;
	// 	// }
	// }
]);
